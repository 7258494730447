<template>
  <div class="hello">
    <h2>{{ msg }}</h2>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <h3>Get Started</h3>
    <ul>
      <li><a href="mailto:support@interthreadsoftware.com">Contact Us</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
